// import Home_Banner from "../../Imges/Home_Banner.jpg";

* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@mixin flexproperties {
  display: flex;
  display: grid;
  justify-content: center;
  align-items: center;
}
@mixin flexJustify {
  display: flex;
  justify-content: center;
}
@mixin flexAlign {
  display: flex;
  align-items: center;
}
@mixin GridRowmaxautomax {
  display: grid;
  grid-template-rows: max-content auto max-content;
}
@mixin GridRowmaxauto {
  display: grid;
  grid-template-rows: max-content auto;
}
@mixin GridColmaxautomax {
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
}
@mixin GridRowautomax {
  display: grid;
  grid-template-rows: auto max-content;
}
@mixin GridColautomax {
  display: grid;
  grid-template-columns: auto max-content;
  // align-items: center;
}

@mixin GridColautomaxauto {
  display: grid;
  grid-template-columns: auto max-content auto;
}
@mixin GridColautomaxmax {
  display: grid;
  grid-template-columns: auto repeat(2, max-content);
  align-items: center;
}
@mixin GridColmaxmax {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
}
@mixin GridColmaxauto {
  display: grid;
  grid-template-columns: max-content auto;
  align-items: center;
}
@mixin GridRowmaxmax {
  display: grid;
  grid-template-rows: repeat(2, max-content);
}
@mixin GridRowautomaxmax {
  display: grid;
  grid-template-rows: auto repeat(2, max-content);
}
@mixin GridThreeRowmax {
  display: grid;
  grid-template-rows: repeat(3, max-content);
}

@mixin GridFourRowmax {
  display: grid;
  grid-template-rows: repeat(4, max-content);
}
@mixin GridFiveRowmax {
  display: grid;
  grid-template-rows: repeat(5, max-content);
}
@mixin GridThreeColauto {
  display: grid;
  grid-template-columns: repeat(3, auto);
}
@mixin GridColmaxmaxauto {
  display: grid;
  grid-template-columns: repeat(2, max-content) auto;
}
@mixin GridThreeRowauto {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

@mixin GridColautoauto {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
}
@mixin GridRowautoauto {
  display: grid;
  grid-template-rows: repeat(2, auto);
}
@mixin GridRowmaxmaxauto {
  display: grid;
  grid-template-rows: repeat(2, max-content) auto;
}
@mixin GridThreeColmax {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  align-items: center;
}
@mixin GridFourColmax {
  display: grid;
  grid-template-columns: repeat(4, max-content);
  align-items: center;
}
@mixin GridFourColauto {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
}
@mixin GridFiveColmax {
  display: grid;
  grid-template-columns: repeat(5, max-content);
  align-items: center;
}
@mixin GridSixColmax {
  display: grid;
  grid-template-columns: repeat(6, max-content);
  align-items: center;
}

@mixin flexText {
  font-weight: 500;
  display: flex;
  color: rgb(69, 75, 79);
}

@keyframes colourFull_Img {
  0% {
  }
  50% {
    transform: translate3d(-3.7rem, -2rem, 1rem);
  }
  100% {
  }
}

.Home_Page {
  overflow: auto;
  background-color: #fff;
  z-index: 3;
  height: 100%;
  // padding-top: 90px;
  overflow-x: hidden;
  .Section_One {
    background: url(../../Imges/Home_Banner.jpg), rgba(0, 0, 0, 0.345);
    height: 300px;
    background-size: cover;
    background-position: 100%;
    display: grid;
    background-blend-mode: multiply;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 20px;
      font-weight: 600;
      color: #fff;
    }
  }
}
