@import "/src/components/Home/Biz_Home.module.scss";

.Header {
  // @include GridRowmaxmax;
  gap: 18px;
  padding: 0px 50px 0px 100px;
  right: 0;
  left: 0;
  // background: linear-gradient(to right, #33373d, #82a9d2);
  // background: rgb(32, 42, 59);
  background-color: #192329;
  position: sticky;
  top: 0;
  z-index: 999999999;
  height: 90px;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @include GridColmaxauto;
  gap: 30px;

  .Client_Logo {
    > img {
      width: 250px;
      height: 60px;
    }
  }
  .MenuListing {
    display: grid;
    grid-template-columns: auto max-content;
    align-items: center;
    .Lsit {
      ul {
        @include GridSixColmax();
        gap: 30px;
        padding: 20px;
        .Data_Link {
          position: relative;
          display: grid;
          grid-template-rows: max-content max-content;
          gap: 10px;
          .link_Div {
            background-color: rgb(255, 255, 255);
            width: 100%;
            height: 5px;
            border-radius: 10px;
            opacity: 0;
            margin-top: -10px;
            transition: 0.5s;
          }
          .ActiveLink {
            background-color: rgb(255, 255, 255);
            width: 100%;
            height: 5px;
            border-radius: 10px;
            opacity: 100;
            margin-top: -10px;
            transition: 0.5s;
          }

          .Menu {
            font-size: 17.1px;
            color: #fff;
            font-weight: 500;
            list-style: none;
            text-decoration: none;
            cursor: pointer;
            margin-top: 10px;
          }
          .Sub_Menu {
            position: absolute;
            display: grid;
            // padding: 20px;
            grid-auto-rows: max-content;
            row-gap: 6px;
            width: 250px;
            background-color: #fff;
            box-shadow: 0px 1px 5px 0px #ccc;
            margin-top: 45px;
            display: none;
            .Menu,
            .Active_Menu {
              font-size: 13.5px;
              color: #192329;
              padding-left: 20px;
              &:hover {
                // opacity: 70%;
                color: brown;
              }
              &:nth-child(7) {
                padding-bottom: 20px;
              }
            }
            .CoreDiv {
              display: grid !important;
              grid-template-columns: auto max-content !important;
            }
            .Active_Menu {
              color: brown;
            }
          }
          &:hover {
            .Sub_Menu {
              display: grid;
            }
            > div {
              opacity: 100%;
            }
          }
        }
      }
    }
    .Search_Div {
      display: flex;
      position: absolute;
      right: 10px;
      input {
        width: 200px;
        height: 30px;
        border: 1px solid #fff;
        padding-left: 8px;
        font-size: 13px;
        color: #2f4b57;
        font-weight: 500;
        border-radius: 2px;
      }
    }
  }
}

.MenuBar {
  display: none;
  > svg {
    font-size: 36px;
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
  }
  .Container {
    height: 100vh;
    width: 100vw;
    background: rgba(32, 42, 59, 0.68);
    position: fixed;
    top: 0;
    right: 0;
    justify-content: flex-end;
    .Section_Bar {
      height: 100vh;
      width: 300px;
      background: rgb(32, 42, 59);
      right: 0;
      position: absolute;
      z-index: 9999999;
      > img {
        display: flex;
        justify-content: center;
        margin: auto;
        width: 200px;
        margin-bottom: 10px;
      }
      > svg {
        font-size: 20px;
        color: #ffffff;
        display: flex;
        cursor: pointer;
        margin: 15px 10px;
        &:hover {
          cursor: pointer;
          opacity: 60%;
        }
      }
      .MenuConatiner {
        background: rgb(32, 42, 59);
        .Menu {
          display: flex;
          list-style: none;
          border-top: 1px solid #fff;
          padding: 4px 10px;
          color: #fff;
          font-weight: 500;
          font-size: 15px;
          text-decoration: none;
          cursor: pointer;
          &:nth-child(5) {
            border-bottom: 1px solid #fff;
          }
          .MenuData {
            color: #fff;
            font-weight: 500;
            text-decoration: none;
            padding: 5px 10px;
            .CoreDiv {
              display: grid !important;
              grid-template-columns: auto max-content !important;
              &:hover {
                .Sub_Menu {
                  display: grid;
                }
              }
            }
          }
        }
      }
    }
  }

  .Sub_Menu {
    display: grid;
    grid-auto-rows: min-content;
    padding-left: 20px;

    .ActiveSubLink {
      color: #9c0722;
      font-size: 13px;
      text-decoration: none;
    }
    .sublink_Div {
      color: #c6c6c6;
      text-decoration: none;
      font-size: 13px;
    }
  }
}

.MenuBar {
  display: none;
}

@media (max-width: 1170px) {
  .Header {
    padding: 0px 50px 0px 30px;
    gap: 0px;
    display: block;
    height: 110px;
    .Client_Logo {
      > img {
        width: 220px;
        height: 50px;
      }
    }
    .MenuListing {
      .Lsit {
        ul {
          @include GridSixColmax();
          gap: 20px;
          padding: 0px 20px;
        }
      }
    }
  }
}

@media (max-width: 830px) {
  .Header {
    padding: 0px 50px 0px 30px;
    height: 70px;
    display: grid;
    .MenuListing {
      display: none !important;
    }
    .MenuBar {
      display: grid;

      > svg {
        color: #fff;
      }
    }
  }
}
