.Careers_Page {
  .Section_Img {
    // background: url(https://mind.hawkcdn.in/wp-content/uploads/2022/04/Career-Banner-1.jpg),
    //   rgba(0, 0, 0, 0);

    background-size: cover;
    display: grid;
    > img {
      display: grid;
      width: 100%;
      height: 300px;
    }
  }
  .Section_Two {
    display: grid;
    grid-template-columns: minmax(0px, 600px) max-content;
    gap: 50px;
    padding-top: 80px;
    align-items: center;
    justify-content: center;
    .Content {
      display: grid;
      grid-template-rows: repeat(2, max-content);
      gap: 10px;
      span {
        font-size: 15px;
        font-weight: 500;
        color: rgb(81, 95, 109);
      }
      h4 {
        font-size: 30px;
        font-weight: 700;
        padding: 10px 0px 20px 0px;
        color: rgb(30, 44, 60);
      }
    }
    .Img {
      display: flex;
      > img {
        display: flex;
        width: 40vw;
      }
    }
  }
  .Form {
    display: grid;
    justify-content: center;
    margin: auto;
    width: max-content;
    padding-bottom: 80px;
    h3 {
      font-size: 18px;
      color: rgb(30, 42, 49);
      font-weight: 700;
      border-bottom: 1px solid #ccc;
      padding: 10px 0px;
    }
    label {
      font-size: 16px;
      color: #404e5a;
      font-weight: 400;
      span {
        color: rgb(245, 51, 51);
      }
    }
    .Personal_Info,
    .Academic {
      display: grid;
      grid-template-rows: repeat(2, max-content);
      gap: 30px;
      .Form_Content {
        display: grid;
        grid-auto-rows: max-content;
        gap: 20px;
        .Data {
          display: grid;
          grid-template-rows: repeat(2, max-content);
          gap: 7px;

          > div {
            display: grid;
            grid-template-columns: repeat(2, 350px);
            gap: 15px;
            input {
              border: 1px solid #ccc;
              height: 40px;
              display: flex;
              padding-left: 7px;
              color: #313e48;
              font-weight: 500;
            }
            input[type="text"]::placeholder {
              padding-left: 7px;
              color: #313e48;
              font-weight: 500;
            }
          }
          &:nth-child(3) {
            > div {
              display: grid;
              grid-template-columns: 100%;
            }
          }
        }
      }
    }
    .Academic {
      padding-top: 50px;
      .Data {
        gap: 1px !important;
        > div {
          display: grid;
          grid-template-columns: repeat(2, 150px) !important ;
          // gap: 50px;
          > div {
            display: grid;
            grid-template-columns: repeat(2, max-content);
            gap: 10px;
            align-items: center;
          }
        }
        &:nth-child(1),
        &:nth-child(4) {
          gap: 7px !important;
          > div {
            display: grid;
            grid-template-columns: 100% !important;
          }
        }
      }
      p {
        display: flex;
        font-size: 13px;
        font-weight: 500;
        color: #404e5a;
      }
    }
    .Document_Upload {
      padding-top: 50px;
      display: grid;
      grid-template-rows: repeat(2, max-content);
      gap: 30px;
      .Form_Content {
        display: grid;
        grid-auto-rows: max-content;
        gap: 20px;

        .Data {
          display: grid;
          grid-template-rows: repeat(2, max-content);
          gap: 7px;
          > div {
            display: grid;
            textarea {
              padding-left: 7px;
            }
          }
          &:nth-child(2) {
            > div {
              border: 3px dashed #a5a5a5;
              height: 140px;
              display: flex;
              align-items: center;
              gap: 15px;
              justify-content: center;
              span,
              svg {
                font-size: 23px;
                font-weight: 500;
                color: #8e979e;
                display: flex;
                align-items: center;
                justify-content: center;
                > p {
                  color: rgb(255, 221, 0);
                  padding-left: 7px;
                  &:hover {
                    cursor: pointer;
                    opacity: 60%;
                  }
                }
              }
              svg {
                font-size: 50px;
              }
            }
          }
        }
      }
    }
    .Submit_Button {
      padding-top: 40px;
      display: grid;
      position: relative;
      button {
        height: 50px;
        position: relative;
        background-color: #319ff3;
        display: flex;
        border: 1px solid #1064a4;
        font-size: 21px;
        font-weight: 500;
        color: #fff;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;
        transition: 0.2s;
        &:hover {
          cursor: pointer;
          opacity: 90%;
        }
        &:active {
          transform: scale(0.97);
          opacity: 70%;
        }
      }
    }
  }
}

@media (max-width: 750px) {
  .Careers_Page {
    .Section_Two {
      padding: 0px 20px;
      display: grid;
      grid-template-rows: max-content max-content;
      grid-template-columns: 100%;
      gap: 30px;
      padding-top: 20px;
      .Img {
        display: flex;
        margin: auto;
        justify-content: center;
        > img {
          display: flex;
          width: 40vw;
          justify-content: center;
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .Section_Img {
    > img {
      height: 150px !important;
    }
  }
}



@media (max-width: 450px) {
  .Careers_Page {
    .Section_Two {
      .Content {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        gap: 10px;
        span {
          font-size: 13px;
        }
        h4 {
          font-size: 19px;
        }
      }
    }
  }
}
