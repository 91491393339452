.Section_Img,
.Litigation_Section_Img,
.White_Section_Img,
.Corporate_Section_Img,
.Intellectual_Section_Img,
.Transation_Section_Img {
  background: url(../../../Imges/securities-law-1.jpg), rgba(0, 0, 0, 0.345);
  height: 300px;
  background-size: cover;
  background-position: 100%;
  display: grid;
  background-blend-mode: multiply;
  justify-content: center;
  background-repeat: no-repeat;
  align-items: center;
  h4 {
    font-size: 40px;
    font-weight: 600;
    color: #fff;
  }
}
h5 {
  font-size: 35px;
  color: #253348;
}
.Litigation_Section_Img {
  background: url(../../../Imges/Services-Banners.jpg), rgba(0, 0, 0, 0.345);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}
.White_Section_Img {
  background: url(../../../Imges/White-Collar-Crime-Banner.jpg),
    rgba(0, 0, 0, 0.345);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}
.Corporate_Section_Img {
  background: url(../../../Imges/Corporate-Law-banner.jpg), rgba(0, 0, 0, 0.345);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.Intellectual_Section_Img {
  background: url(../../../Imges/Intellectual-Property-Banner.jpg),
    rgba(0, 0, 0, 0.345);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.Transation_Section_Img {
  background: url(../../../Imges/Transaction-Advisory-Banner.jpg),
    rgba(0, 0, 0, 0.345);
  background-repeat: no-repeat;
  background-position: 100%;
  background-size: cover;
}

.Section_Core {
  padding: 0px 150px;
  padding-bottom: 40px;
  .Section_Div {
    display: grid;
    grid-template-columns: max-content 1fr;
    gap: 30px;
    padding-top: 50px;
    .Links_Contant {
      display: grid;
      grid-template-rows: repeat(7, max-content);
      gap: 12px;
      .Menu_Core,
      .Active_Core {
        border: 1px solid #b7b7b7;
        border-radius: 30px 0px 30px 0px;
        font-size: 16px;
        font-weight: 600;
        color: #253348;
        text-decoration: none;
        padding: 22px 20px;
        transition: 0.2s;
        align-items: center;
        display: grid;
        grid-template-columns: auto max-content;
        &:hover {
          background-color: #861f19;
          color: #fff;
        }
      }
      .Active_Core {
        background-color: #861f19;
        color: #fff;
      }
    }
    .Data_Contant {
      display: grid;
      grid-auto-rows: max-content;
      gap: 30px;
      > p {
        font-size: 16px;
        display: flex;
        font-weight: 500;
        color: #354254;
      }
      > h4 {
        font-size: 21px;
        font-weight: 600;
        color: #253348;
      }
      .Content_Div,
      .Content_Div_Two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        row-gap: 30px;
        > div {
          display: grid;
          grid-template-columns: max-content auto;
          gap: 10px;
          font-size: 15.5px;
          font-weight: 500;
          color: #354254;
          svg {
            margin-top: 5px;
          }
          //   font-size: 50px;
        }
      }
      .Content_Div_Two {
        display: grid;
        grid-template-columns: 100%;
        grid-auto-rows: max-content;
        gap: 20px;
      }
    }
  }
}
.Slider_Div {
  overflow: hidden;
  padding-top: 30px;
  .Slider {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    gap: 10px;
    .Card_Div {
      display: flex;
      justify-content: space-around;
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
  }
}

@media (max-width: 1100px) {
  .Section_Core {
    padding: 0px 50px;
    padding-bottom: 40px;
  }
}

@media (max-width: 1100px) {
  .Section_Core {
    .Section_Div {
      grid-template-columns: 100%;
      grid-template-rows: max-content max-content;
      gap: 30px;
      .Links_Contant {
        display: grid;
        grid-template-columns: repeat(3, auto);
        grid-template-rows: 60%;
        column-gap: 10px;
        row-gap: 20px;
        gap: 0px;
        column-gap: 10px;
        .Menu_Core,
        .Active_Core {
          border-radius: 20px 0px 20px 0px;
          padding: 0px 14px 0px 14px !important;
          height: 30px;
          &:nth-child(7) {
            margin-top: 20px;
          }
        }
        .Active_Core {
          background-color: #861f19;
          color: #fff;
        }
      }
      .Data_Contant {
        padding-top: 20px;
      }
    }
  }
}

@media (max-width: 850px) {
  .Section_Core {
    padding: 0px 20px;
    .Section_Div {
      .Links_Contant {
        .Menu_Core,
        .Active_Core {
          border-radius: 20px 0px 20px 0px;
          padding: 0px 14px 0px 14px !important;
          height: 45px;
          font-size: 12px !important;
        }
        .Active_Core {
          background-color: #861f19;
          color: #fff;
          font-size: 12px;
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .Section_Img,
  .Litigation_Section_Img,
  .White_Section_Img,
  .Corporate_Section_Img,
  .Intellectual_Section_Img,
  .Transation_Section_Img {
    height: 150px !important;
    h4 {
      font-size: 22px;
    }
  }

  .Section_Core {
    .Section_Div {
      .Links_Contant {
        .Menu_Core,
        .Active_Core {
          &:nth-child(7) {
            margin-top: 0px;
          }
        }
      }
    }
  }
}
@media (max-width: 530px) {
  .Section_Core {
    .Section_Div {
      .Links_Contant {
        grid-template-columns: repeat(2, auto);
        grid-template-rows: 24%;
        column-gap: 10px;
        row-gap: 20px;
        .Menu_Core,
        .Active_Core {
          height: 30px;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .Section_Core {
    .Section_Div {
      .Links_Contant {
        column-gap: 5px;
        .Menu_Core,
        .Active_Core {
          font-size: 11px !important;
          height: 50px;
        }
      }
    }
  }
}
