.About_Us {
  position: relative;
  top: 0;
  .Section_Img {
    background: url(../../../Imges/About-Us-Banner.png), rgba(0, 0, 0, 0.345);
    height: 300px;
    background-size: cover;
    background-position: 100%;
    display: grid;
    background-blend-mode: multiply;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 40px;
      font-weight: 600;
      color: #fff;
    }
  }
  .Section_Two {
    display: grid;
    grid-template-columns: minmax(300px, 550px) auto;
    gap: 50px;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    position: relative;
    span,
    p {
      font-size: 17px;
      font-weight: 700;
      color: rgb(117, 135, 154);
      position: relative;
    }
    span::before {
      width: 60px;
      display: flex;
      align-items: center;
      height: 5px;
      content: "";
      position: absolute;
      right: 0;
      margin-right: -72px;
      margin-top: 10px;
      background-color: #7d3935;
      border-radius: 1px;
    }
    p {
      font-size: 15px;
      font-weight: 500;
      color: rgb(81, 95, 109);
    }
    h4 {
      font-size: 30px;
      font-weight: 700;
      padding: 10px 0px 20px 0px;
      color: rgb(30, 44, 60);
    }
    .Img {
      img {
        display: flex;
        // width: 30vw;
        // height: 300px;
      }
    }
  }
  .Card_Section_Container {
    display: flex;
    justify-content: space-evenly;

    padding-top: 40px;
    .Card {
      border: 1px solid #ccc;
      width: 350px;
      display: grid;
      height: auto;
      grid-template-rows: repeat(3, max-content);

      gap: 10px;
      justify-content: center;
      text-align: center;
      background-color: #812d3f;
      padding: 15px;
      justify-content: center;
      h3 {
        font-size: 24px;
        color: #fff;
        font-weight: 600;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        color: #f0eeee;
        text-align: left;
      }
      svg {
        font-size: 35px;
        display: flex;
        justify-content: center;
        margin: auto;
        color: #fff;
      }
    }
  }
  .Section_Three {
    display: grid;
    padding-top: 150px;
    width: 100%;
    .Conatiner_Div {
      display: grid;
      grid-template-columns: max-content minmax(0, 600px);
      gap: 100px;
      justify-content: center;
    }
    .Data {
      display: grid;
      // .Process_Div {
      //   display: grid;
      //   grid-auto-rows: max-content;
      //   gap: 20px;
      //   position: relative;
      //   .details {
      //     display: grid;
      //     grid-template-rows: max-content max-content;
      //     gap: 9px;
      //     position: relative;
      //     span {
      //       color: #202e3f;
      //       font-size: 17px;
      //       font-weight: 600;
      //     }

      //     .Percentage_Div {
      //       display: grid;
      //       grid-template-rows: repeat(2, max-content);
      //       width: 40px;
      //       // align-items: center;
      //       text-align: center;
      //       justify-content: center;
      //       top: -10px;
      //       position: absolute;
      //       right: 3%;
      //       > p {
      //         background-color: #000;
      //         color: #fff;
      //         display: flex;
      //         align-items: center;
      //         justify-content: center;
      //         padding: 4px 7px;
      //         font-size: 13px;
      //       }
      //       svg {
      //         font-size: 14px;
      //         display: flex;
      //         justify-content: center;
      //         text-align: center;
      //         margin-left: 15px;
      //         margin-top: -3px;
      //       }
      //     }
      //     &:nth-child(2) {
      //       .Percentage_Div {
      //         right: 7%;
      //       }
      //     }
      //     &:nth-child(3) {
      //       .Percentage_Div {
      //         right: 5%;
      //       }
      //     }
      //     > div {
      //       height: 10px;
      //       display: grid;
      //       width: 100%;
      //       // border: 1px solid #861f19;
      //       background-color: #dcdcdc;
      //       .Color_Div_First,
      //       .Color_Div_Second,
      //       .Color_Div_Third {
      //         display: flex;
      //         width: 95%;
      //         background-color: #861f19;
      //       }
      //       .Color_Div_Second {
      //         width: 90%;
      //       }
      //       .Color_Div_Third {
      //         width: 93%;
      //       }
      //     }
      //   }
      // }
      > span {
        padding-top: 30px;
        font-size: 16px;
        color: #39414d;
        font-weight: 500;
      }
    }
  }
  .Caore_Practicies_Section {
    display: grid;
    grid-template-rows: repeat(2, max-content);
    gap: 50px;
    padding-top: 80px;
    justify-content: center;

    h4 {
      display: flex;
      justify-content: center;
      font-size: 30px;
      color: #39414d;
    }
    .Conatainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;
      .Img_Data {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        gap: 10px;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        .IMG {
          display: flex;
          border: 2px solid #ccc;
          width: 170px;
          height: 170px;
          justify-content: center;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          margin: auto;
          overflow: hidden;

          img {
            border-radius: 50%;
            width: 160px;
            height: 160px;
            display: flex;
            justify-content: center;
            margin: auto;
            transition: 0.2s;
          }
        }

        label {
          font-size: 20px;
          font-weight: 500;
          color: #39414d;
          display: flex;
          justify-content: center;
          text-align: center;
        }
        &:hover {
          cursor: pointer;
          .IMG {
            border: 5px solid #fcb50e;
          }
          label {
            color: #fcb50e;
          }
          img {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .Testimonial {
    display: grid;
    grid-template-rows: repeat(2, max-content);
    justify-content: center;
    margin: 150px 0px;
    padding-top: 50px;
    background-image: url(https://demo.casethemes.net/consultio/wp-content/uploads/2019/12/bg-section-01.png);
    // background-position: 100%;
    background-repeat: no-repeat;
    .Heading {
      display: grid;
      position: relative;
      grid-template-columns: max-content;
      gap: 100px;
      padding-bottom: 20px;
      align-items: center;
      justify-content: center;
      .Left {
        display: grid;
        position: relative;
        span {
          font-size: 17px;
          font-weight: 700;
          color: #7d3935;
          position: relative;
        }

        h4 {
          font-size: 30px;
        }
      }
      .Right {
        p {
          font-size: 15px;
          color: #202e3f;
          font-weight: 500;
        }
      }
    }
    .Card_Section {
      display: grid;
      justify-content: center;
      grid-template-columns: minmax(0px, 600px) minmax(0px, 600px);
      gap: 30px;
      .Card {
        display: grid;
        grid-template-rows: repeat(2, max-content);
        gap: 5px;
        grid-template-columns: 100%;
        justify-content: center;
        box-shadow: 0 12px 24px rgba(29, 27, 76, 0.1);
        padding: 30px;
        transition: 0.3s;
        .Top_Img {
          display: flex;
          justify-content: flex-end;
          transition: 0.3s;

          .Img {
            border: 13px solid #cccccc9a;
            border-radius: 50%;
            display: flex;
            width: 100px;
            height: 100px;
            position: relative;
            justify-content: flex-end;
            right: 0%;
            transition: 0.3s;
            img {
              display: flex;
              border-radius: 50%;
            }
            .Logo {
              background-color: #000;
              position: absolute;
              transition: 0.2s;
              top: -10px;
              right: -15px;
              width: 40px;
              height: 40px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              svg {
                color: #fff;
                font-size: 25px;
              }
            }
          }
        }
        .Data {
          display: grid;
          grid-template-rows: repeat(3, max-content);
          gap: 12px;
          svg {
            color: rgb(250, 172, 55);
            font-size: 25px;
          }
          .Review {
            display: flex;
          }
          h5 {
            font-size: 20px;
            color: #364e6a;
            span {
              color: #2b5283;
              font-size: 15px;
              padding-left: 5px;
              font-weight: 500;
            }
          }
          span {
            font-weight: 500;
            font-size: 14px;
            color: #44515b;
          }
        }
        &:hover {
          cursor: pointer;
          box-shadow: 0 22px 34px rgba(27, 30, 42, 0.201);
          .Top_Img {
            .Img {
              border: 13px solid #000;
              .Logo {
                background-color: #ffc746;

                svg {
                  color: #000000;
                }
              }
            }
          }
        }
      }
    }
  }
}

.Disclaimer_Section {
  display: grid;
  position: fixed;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  background-color: #021020e3;
  // top: 30%;
  right: 0;
  bottom: 0;
  .Disclaimer {
    display: grid;
    position: absolute;
    top: 20%;
    left: 15%;
    width: 70vw;
    min-height: 420px;
    background-color: #fff;
    grid-template-rows: max-content auto max-content;
    z-index: 999999999;
    .Header {
      display: grid;
      grid-template-columns: auto max-content;
      padding: 5px 10px;

      align-items: center;
      border-bottom: 1px solid #ccc;
      background-color: rgb(75, 128, 226);
      color: #fff;
      img {
        width: 200px;
      }
      h4 {
        font-size: 18px;
        font-weight: 600;
      }
    }
    .Content {
      padding: 20px;
      span {
        font-weight: 500;
        color: #364e6a;
        // line-height: -5px;
        font-size: 15px;
      }
    }
    .Footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0px 10px 10px 0px;
      .button {
        text-decoration: none;
      }
      button {
        display: flex;
        font-size: 15px;
        font-weight: 500;
        color: #fff;
        background-color: cornflowerblue;
        padding: 7px 20px;
        border: 1px solid rgb(34, 73, 145);
        border-radius: 2px;
        &:hover {
          cursor: pointer;
          opacity: 70%;
        }
      }
    }
  }
}

@media (max-width: 950px) {
  .Section_Two {
    img {
      width: 30vw;
    }
  }
  .Section_Three {
    img {
      width: 30vw;
    }
  }
}

@media (max-width: 800px) {
  .Section_Two {
    padding: 20px;
    display: block !important;
    img {
      width: 30vw;
      justify-content: center;
      display: flex;
      margin: auto;
    }
  }
  .Section_Three {
    margin-top: -100px;
    .Conatiner_Div {
      padding: 20px;
      display: block !important;
      img {
        width: 30vw;
        justify-content: center;
        display: flex;
        margin: auto;
      }
    }
  }
  .Testimonial {
    .Card_Section {
      display: block !important;
      margin: auto;
      padding: 20px;
    }
  }
}

@media (max-width: 800px) {
  .Conatainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 50px;
  }
}

@media (max-width: 550px) {
  .About_Us {
    .Section_Img {
      height: 150px !important;
      h4 {
        font-size: 22px !important;
      }
    }
  }

  .Disclaimer_Section {
    .Disclaimer {
      top: 12%;
      left: 5%;
      width: 90vw;
      min-height: 420px;
      .Header {
        padding: 2px 10px;
        img {
          width: 150px;
        }
      }
      .Content {
        padding: 10px;
        span {
          font-size: 10px;
        }
      }
    }
  }

  .Card_Section_Container {
    display: grid !important;
    grid-template-rows: repeat(3, max-content) !important;
    gap: 20px;
    padding-top: 40px;
    .Card {
      border: 1px solid #ccc;
      width: 350px;
      h3 {
        font-size: 24px;
      }
      span {
        font-size: 14px;
      }
      svg {
        font-size: 35px;
      }
    }
  }
}

@media (max-width: 410px) {
  .About_Us {
    .Testimonial {
      .Heading {
        .Left {
          h4 {
            font-size: 17px;
          }
        }
      }
    }
    .Caore_Practicies_Section {
      h4 {
        font-size: 20px;
      }
      .Conatainer {
        .Img_Data {
          .IMG {
            width: 120px;
            height: 120px;

            img {
              width: 130px;
              height: 130px;
            }
          }
          label {
            font-size: 14px;
          }
        }
      }
    }

    .Card_Section_Container {
      display: grid !important;
      grid-template-rows: repeat(3, max-content) !important;
      gap: 20px;
      padding: 40px 20px;
      .Card {
        border: 1px solid #ccc;
        width: auto;
        h3 {
          font-size: 20px;
        }
        span {
          font-size: 14px;
        }
        svg {
          font-size: 35px;
        }
      }
    }
  }

  
}
