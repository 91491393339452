.Footer {
  display: grid;
  // justify-content: center;
}
.Section_Footer {
  display: grid;
  grid-template-columns: 1fr 1.2fr repeat(2, 1fr);
  // background: rgb(32, 42, 59);
  background-color: #192329;
  color: #fff;
  padding: 50px 100px;
  .Office_Span {
    color: #fff;
    text-decoration: none;
    &:hover {
      margin-left: 2px;
      opacity: 60%;
    }
  }
  .data_Div {
    display: grid;
    grid-template-rows: max-content;
    align-items: center;
    justify-content: center;
    img {
      width: 250px;
    }
    span {
      display: grid;
      gap: 5px;
    }
    h4 {
      align-items: flex-end;
      display: flex;
      font-weight: 600;
      font-size: 20px;
    }
    ul {
      display: grid;
      gap: 5px;
      position: relative;
      grid-template-rows: repeat(4, max-content);
      li {
        font-size: 16px;
        font-weight: 500;
        position: relative;
        transition: 0.1s;
        &:hover {
          padding-left: 0px;
          cursor: pointer;
        }
      }
    }

    > div {
      display: grid;
      grid-template-rows: max-content max-content;
      gap: 20px;
      .Social_Logo {
        display: flex;
        gap: 10px;
        svg {
          font-size: 30px;
          border-radius: 3px;
          &:nth-child(1) {
            background-color: rgb(11, 73, 135);
          }
          &:nth-child(2) {
            background-color: dodgerblue;
          }
          &:nth-child(3) {
            background-color: rgb(198, 5, 124);
          }
          &:nth-child(4) {
            background-color: rgb(87, 142, 198);
          }
        }
      }
    }
  }
}
.Sub_Footer {
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #dbd7d7;
  .Policyes {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    gap: 10px;
    span {
      border-right: 1px solid #918b8b;
      padding: 0px 15px;
      font-size: 15px;
      color: #374957;
      font-weight: 500;
      &:nth-child(3) {
        border-right: none;
      }
    }
  }
}

@media (max-width: 900px) {
  .Section_Footer {
    padding: 50px 20px;
  }
}

@media (max-width: 900px) {
  .Section_Footer {
    display: grid;
    grid-template-columns: 1fr 1.2fr repeat(1, 1fr);
    background: rgb(32, 42, 59);
    color: #fff;
    position: relative;
    padding: 50px 20px;

    .data_Div {
      padding-top: 50px;
      padding-left: 20px;
      img {
        width: 250px;
        position: absolute;
        top: 5px;
        margin: auto;
        justify-content: center;
        left: 30%;
      }
      &:nth-child(1) {
        padding-top: 0px;
        position: absolute;
      }
    }
  }
}

@media (max-width: 600px) {
  .Sub_Footer {
    height: max-content;
    display: block;
    padding: 10px 20px;
    justify-content: center;
    span {
      display: flex;
      justify-content: center;
      padding-bottom: 12px;
    }
    .Policyes {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 10px;
      margin: auto;
      justify-content: center;
    }
  }
}

@media (max-width: 450px) {
  .Footer{
  .Section_Footer {
    padding: 50px 20px;
    .data_Div {
      h4{
        font-size: 14px;
      }
      > div {
        .Social_Logo {
          display: grid !important;
          grid-auto-rows: max-content;
          padding: 30px 30px 0px 
          30px;
        }
        p{
          position: absolute;
          bottom: 0;
          right: 30%;
        }
      }
    }
    }
  }
  .Sub_Footer {
    height: max-content;
    display: block;
    padding: 0px 0px;
  }
}
