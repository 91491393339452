.Our_Team {
  .Section_Img {
    background: url(../../../Imges/Team-Banner.jpg), rgba(0, 0, 0, 0.345);
    height: 300px;
    background-size: cover;
    background-position: 100%;
    display: grid;
    background-blend-mode: multiply;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 40px;
      font-weight: 600;
      color: #fff;
    }
  }
  > span {
    font-size: 23px;
    color: #7d1d1d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: 20px;
  }
  > p {
    font-size: 26px;
    color: #273f53;
    font-weight: 500;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .Team_Card_Section {
    display: grid;
    grid-template-columns: repeat(3, 352px);
    grid-template-columns: minmax(100px, 352px) minmax(100px, 352px) minmax(
        100px,
        352px
      );
    gap: 25px;
    justify-content: center;
    padding: 50px 20px 20px 20px;
    .Card {
      height: 350px;
      position: relative;
      overflow: hidden;
      .img {
        display: flex;
        img {
          height: 350px;
          width: 350px;
          display: flex;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
      }
      .Content {
        background-color: #232425;
        background: linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 1) 60%,
          rgba(0, 0, 0, 0) 100%
        );
        text-align: center;
        justify-content: center;
        position: absolute;
        padding: 0px 10px 0px 10px;
        transition: 0.2s;
        // bottom: 0;
        top: 280px;
        // display: grid;
        height: 350px;
        width: 100%;
        h4 {
          font-size: 25px;
          font-weight: 600;
          color: #fff;
        }
        span,
        p {
          font-size: 12.5px;
          font-weight: 500;
          color: #e7e7e7;
        }
        p {
          padding-top: 14px;
        }
      }
      &:hover {
        cursor: pointer;
        .Content {
          margin-top: -200px;
        }
      }
    }
  }
  .SubCard_Section {
    display: grid;
    grid-template-columns: minmax(100px, 352px) minmax(100px, 352px) minmax(
        100px,
        352px
      );
    gap: 25px;
    justify-content: center;
    row-gap: 40px;
    padding: 100px 20px;
    .Card {
      padding: 30px 0px;
      // border: 1px solid #ccc;
      position: relative;
      overflow: hidden;
      justify-content: center;
      transition: 0.2s;
      .img {
        display: flex;
        justify-content: center;
        img {
          // height: 100px;
          border-radius: 50%;
          width: 100px;
          display: flex;
          justify-content: center;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }
      }
      span {
        color: #273f53;
        font-weight: 600;
        text-align: center;
        font-size: 18px;
        justify-content: center;
        display: flex;
      }
      p {
        color: #273f53;
        font-size: 13px;
        text-align: center;
      }

      &:hover {
        box-shadow: 0px 10px 10px 0px #e0e5e7;
        box-shadow: 0 20px 20px rgba(2, 20, 54, 0.2);
      }
    }
  }
}

@media (max-width: 920px) {
  .Our_Team {
    .Team_Card_Section {
      grid-template-columns: minmax(100px, 352px) minmax(100px, 352px);
    }
  }
}

@media (max-width: 650px) {
  .Our_Team {
    .Team_Card_Section {
      .Card {
        height: 250px;
        .img {
          img {
            // height: 100px;
            width: 100%;
            height: 350px;
            display: flex;
          }
        }
        .Content {
          top: 200px;
          height: 250px;

          h4 {
            font-size: 18px;
          }
          span,
          p {
            font-size: 11.5px;
          }
          p {
            padding-top: 10px;
          }
        }

        &:hover {
          cursor: pointer;
          .Content {
            margin-top: -200px;
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .Our_Team {
    .Team_Card_Section {
      grid-auto-rows: max-content !important;
      gap: 10px;
      grid-template-columns: 100%;
      .Card {
        height: 250px;
        .img {
          img {
            // height: 100px;
            width: 100%;
            height: 350px;
            display: flex;
          }
        }
        .Content {
          top: 185px;
          height: 350px;
          h4 {
            font-size: 20px;
          }
          span,
          p {
            font-size: 12.5px;
          }
        }
        &:hover {
          cursor: pointer;
          .Content {
            margin-top: -185px;
          }
        }
      }
    }

    .SubCard_Section {
      display: grid;
      grid-template-columns: minmax(100px, 352px) minmax(100px, 352px);
    }
  }
}

@media (max-width: 550px) {
  .Our_Team {
  .Section_Img {
  
      height: 150px !important;
      h4{
        font-size: 22px !important;
      }
  }
  }
}
