.OurPresence {
  display: grid;

  .Section_Img {
    background: url(https://mind.hawkcdn.in/wp-content/uploads/2022/04/Our-Presence-Banner.jpg),
      rgba(0, 0, 0, 0.345);
    height: 300px;
    background-size: cover;
    background-position: 100%;
    display: grid;
    background-blend-mode: multiply;
    justify-content: center;
    align-items: center;
    h4 {
      font-size: 40px;
      font-weight: 600;
      color: #fff;
    }
  }
  .Location_Card {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: center;
    gap: 20px;
    padding: 40px 20px;

    .Card {
      grid-template-columns: max-content auto;
      padding: 10px 20px;
      gap: 20px;
      display: grid;
      //   width: 300px;
      border: 1px solid #ccc;
      a, .SVG {
        width: 50px;
        border-radius: 50%;
        height: 50px;
        background-color: rgb(255, 208, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          color: #ffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
        }
      }
      h4 {
        font-size: 20px;
        font-weight: 600;
        color: #293443;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        // padding-top: 10px;
        color: #626d80;
      }
      &:nth-child(1) {
        display: grid;
        column-span: 2/4;
        // grid-column-start: 1;
        // grid-column-end: 2.5;
        grid-row-start: 1;
        grid-row-end: 3;
        padding-top: 40px;
        span {
          padding-top: 30px;
        }
      }
      &:hover {
        cursor: pointer;
        box-shadow: 0px 20px 20px 0px #ccc;
      }
    }
  }
  .Form_Section {
    display: grid;
    grid-template-columns: max-content auto;
    gap: 70px;
    justify-content: center;
    img {
      height: 600px;
    }
    .Section {
      display: grid;
      grid-auto-rows: max-content;
      padding-top: 50px;
      width: 34vw;
      h4 {
        font-size: 27px;
        color: #293443;
        font-weight: 600;
      }
      p {
        font-size: 14px;
        color: #293443;
        font-weight: 500;
        padding: 30px 0px 20px 0px;
      }
      .Form {
        display: grid;
        grid-auto-rows: max-content;
        gap: 20px;
        input {
          display: flex;
          border: 1px solid #ccc;
          height: 45px;
          color: #293443;
          padding-left: 5px;
          font-weight: 500;
        }
        input::placeholder {
          color: #5c6879;
          padding-left: 5px;
          font-weight: 500;
        }
        select {
          display: flex;
          border: 1px solid #ccc;
          height: 45px;
          color: #495668;
          padding: 0px 5px;
          font-weight: 500;
        }

        textarea {
          display: flex;
          border: 1px solid #ccc;
          height: 200px;
          color: #293443;
          padding-left: 5px;
          font-weight: 500;
        }
      }
      button {
        background-color: rgb(0, 157, 255);
        border: 1px solid #105ca2;
        padding: 10px 20px;
        display: flex;
        font-size: 17px;
        font-weight: 500;
        align-items: center;
        justify-content: center;
        gap: 7px;
        color: #fff;

        &:hover {
          cursor: pointer;
          opacity: 90%;
        }
        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
  .Slider_Conatiner {
    overflow: hidden;
    //   height: 200px;
    position: relative;
    padding: 100px 0px;
    .Slider {
      width: 75vw;
      margin: auto;
      justify-content: center;
      display: flex;
      img {
        filter: grayscale(100%);
        opacity: 0.5;
        &:hover {
          filter: grayscale(0%);
          opacity: 100%;
          cursor: pointer;
        }
      }
    }
  }
  .Map {
    display: flex;
    border: 5px solid rgb(244, 192, 128);
    justify-content: center;
    border-radius: 5px;
    margin: 4px 150px 50px 150px;
  }
}

@media (max-width: 910px) {
  .OurPresence{
    .Location_Card {
      display: grid;
      grid-template-columns: repeat(2, auto);
      justify-content: center;
      gap: 20px;
      padding: 40px 20px;
  
      .Card {
        grid-template-columns: max-content auto;
        padding: 10px 20px;
        gap: 20px;
        display: grid;
        //   width: 300px;
        border: 1px solid #ccc;
        a, .SVG {
          width: 50px;
          border-radius: 50%;
          height: 50px;
          background-color: rgb(255, 208, 0);
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            color: #ffff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
          }
        }
        h4 {
          font-size: 20px;
          font-weight: 600;
          color: #293443;
        }
        span {
          font-size: 14px;
          font-weight: 500;
          // padding-top: 10px;
          color: #626d80;
        }
        &:nth-child(1) {
          display: grid;
          grid-row-start: 1 !important;
          grid-row-end: 1 !important ;
          padding-top: 10px;

        }
      
      }
    }
  .Form_Section {
    display: grid;
    // grid-template-rows: max-content max-content;
    // grid-template-columns: 100%;
    gap: 70px;
    justify-content: center;
    img {
      height: 400px;
      justify-content: center;
      align-items: center;
    }
    .Section {
      width: 34vw;
      h4 {
        font-size: 18px;
        color: #293443;
        font-weight: 600;
      }
    }
  }
  }
}

@media (max-width: 700px) {
  .OurPresence{
  .Form_Section {
    img {
      height: 300px;
      justify-content: center;
      align-items: center;
      top: 30%;
      display: none;
    }
    .Section {
      width: 60vw;
      h4 {
        font-size: 18px;
        color: #293443;
        font-weight: 600;
      }
    }
  }

  .Slider_Conatiner{
    padding: 80px 20px;
    .Slider{
      width: 95vw;
      img{
        width: 100px;
      }
    }
  }
  }
}

@media (max-width: 600px) {
  .OurPresence{
  .Location_Card {
    display: grid;
    grid-auto-rows: max-content !important;
    grid-template-columns: 100%;
    justify-content: center;
    gap: 20px;
    padding: 40px 20px;
  }
  }
}

@media (max-width: 550px) {
  .OurPresence {
  .Section_Img {
  
      height: 150px !important;
      h4{
        font-size: 22px !important;
      }
  }
  }
}